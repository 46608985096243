export const brentCrudeOilPrices = [
  {
    date: '2022-10-31',
    price: 93.71857142857142
  },
  {
    date: '2022-09-30',
    price: 90.60818181818185
  },
  {
    date: '2022-08-31',
    price: 97.64304347826089
  },
  {
    date: '2022-07-31',
    price: 105.25285714285714
  },
  {
    date: '2022-06-30',
    price: 117.69272727272728
  },
  {
    date: '2022-05-31',
    price: 112.11363636363635
  },
  {
    date: '2022-04-30',
    price: 106.15571428571427
  },
  {
    date: '2022-03-31',
    price: 112.43999999999997
  },
  {
    date: '2022-02-28',
    price: 94.26700000000001
  },
  {
    date: '2022-01-31',
    price: 85.62238095238094
  },
  {
    date: '2021-12-31',
    price: 74.67826086956524
  },
  {
    date: '2021-11-30',
    price: 80.89045454545453
  },
  {
    date: '2021-10-31',
    price: 83.86523809523811
  },
  {
    date: '2021-09-30',
    price: 74.74954545454544
  },
  {
    date: '2021-08-31',
    price: 70.58818181818181
  },
  {
    date: '2021-07-31',
    price: 74.4031818181818
  },
  {
    date: '2021-06-30',
    price: 73.51272727272728
  },
  {
    date: '2021-05-31',
    price: 68.3752380952381
  },
  {
    date: '2021-04-30',
    price: 65.53181818181818
  },
  {
    date: '2021-03-31',
    price: 65.79608695652173
  },
  {
    date: '2021-02-28',
    price: 62.36250000000001
  },
  {
    date: '2021-01-31',
    price: 55.224761904761905
  }
];
