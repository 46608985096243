export const coffeePrice = [
  {
    date: '2022-10-31',
    price: 240.0823809523809
  },
  {
    date: '2022-09-30',
    price: 267.4922727272728
  },
  {
    date: '2022-08-31',
    price: 268.4834782608696
  },
  {
    date: '2022-07-31',
    price: 255.90666666666667
  },
  {
    date: '2022-06-30',
    price: 273.76363636363635
  },
  {
    date: '2022-05-31',
    price: 260.44954545454544
  },
  {
    date: '2022-04-30',
    price: 265.3976190476191
  },
  {
    date: '2022-03-31',
    price: 258.9934782608696
  },
  {
    date: '2022-02-28',
    price: 279.8335
  },
  {
    date: '2022-01-31',
    price: 271.08142857142855
  },
  {
    date: '2021-12-31',
    price: 268.32913043478266
  },
  {
    date: '2021-11-30',
    price: 258.97818181818184
  },
  {
    date: '2021-10-31',
    price: 241.07333333333332
  },
  {
    date: '2021-09-30',
    price: 225.5368181818182
  },
  {
    date: '2021-08-31',
    price: 216.43454545454543
  },
  {
    date: '2021-07-31',
    price: 204.50090909090906
  },
  {
    date: '2021-06-30',
    price: 192.06454545454542
  },
  {
    date: '2021-05-31',
    price: 186.46285714285713
  },
  {
    date: '2021-04-30',
    price: 168.64863636363637
  },
  {
    date: '2021-03-31',
    price: 167.0508695652174
  },
  {
    date: '2021-02-28',
    price: 166.42900000000003
  },
  {
    date: '2021-01-31',
    price: 160.8195238095238
  }
];
