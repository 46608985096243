import React, { useState } from 'react';
import lexique from './lexique.json';
import { Input } from '@mui/material';
import './style.css';
import Grid from '@mui/material/Grid';

const Glossary = () => {
  const [value, setValue] = useState('');
  return (
    <>
      <div className="lexiqueHeader">
        <h1 style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', color: '#ffffff' }}>Glossary</h1>
        <div className="inputSearch">
          <Input
            placeholder="Search"
            sx={{
              borderBottom: '2px solid #fff',
              marginBottom: '20px',
              color: '#fff',
              width: 200
            }}
            onChange={(event) => setValue(event.target.value)}
          />
        </div>
      </div>
      <div className="lexiqueContainer">
        {lexique
          .filter((term) => term.name.toLowerCase().includes(value.toLocaleLowerCase()))
          .slice(0, 5)
          .map((term, index) => {
            return (
              <Grid
                key={index}
                justifyContent="center"
                container
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, md: 0 }}>
                <Grid sx={{ backgroundColor: '#212121' }} item xs={12} md={6}>
                  <div className="contentLexique" style={{ padding: '30px', color: 'white', marginBottom: '10px' }}>
                    <h3>{term.name}</h3>
                    <p>{term.field_glossary_description}</p>
                  </div>
                </Grid>
              </Grid>
            );
          })}
      </div>
    </>
  );
};

export default Glossary;
