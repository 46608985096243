import React, { useState, useEffect } from 'react';
import './style.css';
import { Grid } from '@mui/material';
import BitcoinRates from '../Graphics/Bitcoin/BitcoinRates';
import Commodities from '../Graphics/Commodities';
import FederalRates from '../Graphics/Federal/FederalRates';
import BCERates from '../Graphics/BCE/BCERates';
import CircularProgress from '@mui/material/CircularProgress';
const Currencies = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {loading ? (
        <Grid sx={{ height: '50vh' }} container direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 5 }}
          spacing={6}>
          <Grid item xs={11} md={5}>
            <h1 className="title" style={{ color: '#fff' }}>
              Bitcoin
            </h1>
            <BitcoinRates />
          </Grid>
          <Grid item xs={11} md={5}>
            <h1 className="title" style={{ color: '#fff' }}>
              Federal Reserve
            </h1>
            <FederalRates />
          </Grid>
          <Grid item xs={11} md={5}>
            <h1 className="title" style={{ color: '#fff' }}>
              Commodities
            </h1>
            <Commodities />
          </Grid>
          <Grid item xs={11} md={5}>
            <h1 className="title" style={{ color: '#fff' }}>
              European Central Bank
            </h1>
            <BCERates />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Currencies;
