import React, { useCallback, useEffect, useState } from 'react';
import './style.css';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import Grow from '@mui/material/Grow';
import SkeletonNews from './SkeletonNews';
import { dateFormater } from '../../data/dateFormater';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
const News = (props) => {
  const { numberArticle } = props;
  News.propTypes = { numberArticle: PropTypes.number };

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNews = useCallback(async () => {
    const data = await fetch(
      `https://api.nytimes.com/svc/topstories/v2/business.json?api-key=uJrjiiwj7SIC8KShu3jHWHqTGaaRfUTN`
    );
    if (!data.ok) {
      console.clear();
      /*  const message = `An error has occured: ${data.status}`;
      throw new Error(message); */
    }
    const response = await data.json();
    setNews(response.results);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchNews().catch((error) => {
      error.message;
    });
  }, [fetchNews]);

  return (
    <>
      {loading && <SkeletonNews numberArticle={numberArticle} />}
      <Grid container columnSpacing={{ xs: 2 }}>
        {!loading &&
          news.slice(0, numberArticle).map((news, index) => {
            return (
              <Grid
                sx={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}
                key={index}
                item
                xs={12}
                md={4}
                columnSpacing={{ xs: 2 }}>
                <Grow in={true} appear={true} timeout="auto">
                  <Box
                    sx={{
                      width: 350,
                      height: '100%',
                      backgroundColor: '#ffffff',
                      display: 'flex',
                      flexFlow: 'column',
                      mx: 10
                    }}>
                    <h1 className="news-title">
                      <a href={news.url} target="_blank" rel="noreferrer">
                        {news.title}
                      </a>
                    </h1>
                    <ImageList sx={{ width: 350, height: 250 }} cols={1} rowHeight={250}>
                      <ImageListItem>
                        {news.multimedia ? (
                          <img
                            key={index}
                            style={{ overflowY: 'hidden' }}
                            width={350}
                            src={`${news.multimedia[0]?.url}?w=350&fit=crop&auto=format`}
                            alt={news?.multimedia?.caption}
                          />
                        ) : (
                          <img
                            key={index}
                            style={{ overflowY: 'hidden' }}
                            width={350}
                            src={'/assets/ny-times-placeholder.png'}
                            alt={news?.multimedia?.caption}
                          />
                        )}
                      </ImageListItem>
                    </ImageList>
                    <p style={{ padding: 25, fontSize: '0.8rem', fontWeight: '500' }}>{news.abstract}</p>
                    <p style={{ padding: 15, paddingLeft: 25, fontSize: '0.7rem', fontColor: '#e0e0e0' }}>
                      {news.byline} - {dateFormater(news.updated_date)}
                    </p>
                    <p className="source-times">source: New York Times</p>
                  </Box>
                </Grow>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default News;
