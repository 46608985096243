import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import News from './components/features/News';
import Currencies from './components/features/Currencies';
import Analysis from './components/features/Analysis';
import Homepage from './components/features/Homepage';
import Glossary from './components/features/Glossary';
import StockIndex from './components/features/StockIndex';
import StocksPage from './components/webpages/StocksIndex';

function App() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/actualites" element={<News numberArticle={6} />} />
        <Route path="/devises" element={<Currencies />} />
        <Route path="/entreprises" element={<StocksPage />} />
        <Route path="/analyses" element={<Analysis />} />
        <Route path="/lexique" element={<Glossary />} />
        <Route path="/ticker" element={<StockIndex />} />
        <Route path="*" element={<Homepage />} />
      </Route>
    </Routes>
  );
}

export default App;
