import { Outlet } from 'react-router-dom';
import Menu from '../Menu';
import LabelBottomNavigation from '../Footer';
import './style.css';

const MainLayout = () => {
  return (
    <>
      <Menu />
      <div className="content">
        <Outlet />
      </div>
      <LabelBottomNavigation />
    </>
  );
};

export default MainLayout;
