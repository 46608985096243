import React from 'react';
import { Chart as ChartJS, CategoryScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, BarElement, Title, Tooltip, Legend);
import { Bar } from 'react-chartjs-2';

const BCERates = () => {
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y + '%';
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 4
      }
    }
  };
  return (
    <Bar
      options={options}
      datasetIdKey="id"
      data={{
        labels: ['01/01/2022', '27/07/2022', '14/09/2022', '02/11/2022'],
        datasets: [
          {
            id: 1,
            label: 'BCE',
            data: [0, 0.5, 1.25, 2],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(33, 150, 243, 1)',
            barThickness: 30
          }
        ]
      }}
    />
  );
};

export default BCERates;
