import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './style.css';
import { getEveryXElement } from '../../../utils/getEveryXelement';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const FiatComparisons = () => {
  const [fiat, setFiat] = useState([]);
  const [lkr, setLkr] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch('/data/chfusd.json');
      const lkr = await fetch('/data/lkrusd.json');
      if (!data.ok) {
        const message = `An error has occured: ${data.status}`;
        throw new Error(message);
      }
      if (!lkr.ok) {
        const message2 = `An error has occured: ${lkr.status}`;
        throw new Error(message2);
      }
      const response = await data.json();
      const responseLkr = await lkr.json();
      setLkr(responseLkr.data.rates);
      setFiat(response.data.rates);
    };
    fetchData().catch(console.error);
  }, []);

  const fiatDates = Object.keys(fiat);
  const fiatValues = Object.values(fiat);

  const lkrData = getEveryXElement(Object.values(lkr), 30);
  // récupérer les dates avec une séparation de 30 jours (pour réduire la taille des données de xAxis)

  const dates = getEveryXElement(fiatDates, 30);
  const values = getEveryXElement(fiatValues, 30);

  const chfValues = dates.map((date, i) => {
    return { chf: values[i].CHF, month: date };
  });

  const lkrValues = dates.map((date, i) => {
    return { lkr: lkrData[i].LKR, month: date };
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true
      }
    },
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 0,
        to: 0.4,
        loop: false
      }
    },
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        min: 0.8,
        max: 1.1
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'left',
        min: 200,
        max: 370
      }
    },
    pointHoverBorderWidth: 5
  };

  const data = {
    datasets: [
      {
        label: 'CHF - USD',
        data: chfValues,
        borderColor: 'rgb(96,125,139)',
        backgroundColor: 'rgba(96,125,139, 0.5)',
        parsing: {
          xAxisKey: 'month',
          yAxisKey: 'chf'
        },
        yAxisID: 'y1'
      },
      {
        label: 'LKR - USD',
        data: lkrValues,
        borderColor: 'rgb(139,195,74)',
        backgroundColor: 'rgba(139,195,74, 0.5)',
        parsing: {
          xAxisKey: 'month',
          yAxisKey: 'lkr'
        },
        yAxisID: 'y2'
      }
    ]
  };
  return (
    <>
      <h2>Currencies rates in 2022</h2>
      {<Line options={options} data={data} />}
      <p className="sourceFiatComparisons">source: Commodities API</p>
    </>
  );
};

export default FiatComparisons;
