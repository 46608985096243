import React from 'react';
import coinData from '../../../data/coinData';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
import { Line } from 'react-chartjs-2';

const BitcoinRates = () => {
  /*   const dateFormater = (timestamp) => {
    const formatDate = new Date(timestamp);
    const dateFormated = formatDate.getDate() + '/' + formatDate.getMonth() + '/' + formatDate.getFullYear();
    return dateFormated;
  };
 */

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: ''
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            let yAxis = context.dataset.yAxisID;
            if (label) {
              label += ': ';
            }
            if (yAxis === 'y2') {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              return label;
            } else if (yAxis === 'y1') {
              label += context.parsed.y + '%';
              return label;
            }
          }
        }
      }
    },
    animations: {
      y1: {
        tension: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 0,
          loop: false
        }
      },
      y2: {
        tension: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 0.5,
          loop: true
        }
      }
    },
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        min: 0,
        max: 5
      },
      y2: {
        type: 'linear',
        display: true,
        min: 0,
        max: 50000
      }
    },
    pointHoverBorderWidth: 5
  };

  const evolCurrency = [
    { month: 'July', ratesPrices: { rate: 1.58, price: coinData[1].rate_high } },
    { month: 'August', ratesPrices: { rate: null, price: coinData[4].rate_high } },
    { month: 'September', ratesPrices: { rate: 2.33, price: coinData[7].rate_high } },
    { month: 'October', ratesPrices: { rate: null, price: coinData[10].rate_high } },
    { month: 'November', ratesPrices: { rate: 3.08, price: coinData[13].rate_high } },
    { month: 'December', ratesPrices: { rate: 3.83, price: coinData[16].rate_high } }
  ];

  const data = {
    datasets: [
      {
        label: 'FED',
        data: evolCurrency,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        parsing: {
          xAxisKey: 'month',
          yAxisKey: 'ratesPrices.rate'
        },
        yAxisID: 'y1',
        pointStyle: 'triangle',
        pointRadius: 6
      },
      {
        label: 'Bitcoin',
        data: evolCurrency,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.5,
        parsing: {
          xAxisKey: 'month',
          yAxisKey: 'ratesPrices.price'
        },
        yAxisID: 'y2'
      }
    ]
  };
  return <>{<Line options={options} data={data} />}</>;
};

export default BitcoinRates;
