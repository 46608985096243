import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Link } from 'react-router-dom';

export default function LabelBottomNavigation() {
  return (
    <BottomNavigation sx={{ height: '100%', marginTop: '50px', backgroundColor: '#eeeeee' }}>
      <BottomNavigationAction
        sx={{ minWidth: '0' }}
        LinkComponent={Link}
        to={'/'}
        label="Homepage"
        value="Homepage"
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        LinkComponent={Link}
        to={'/devises'}
        label="Devises"
        value="devises"
        icon={<CurrencyExchangeIcon />}
      />
      <BottomNavigationAction
        LinkComponent={Link}
        to={'/analyses'}
        label="Analyses"
        value="analyses"
        icon={<AnalyticsIcon />}
      />
      <BottomNavigationAction
        LinkComponent={Link}
        to={'/lexique'}
        label="Glossary"
        value="glossary"
        icon={<LibraryBooksIcon />}
      />
      <BottomNavigationAction
        LinkComponent={Link}
        to={'/entreprises'}
        label="Search index"
        value="searchIndex"
        icon={<ShowChartIcon />}
      />
    </BottomNavigation>
  );
}
