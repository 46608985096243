import { Divider, Grid } from '@mui/material';
import React from 'react';
import BigMacIndex from '../../features/Markets/BigMacIndex';
import StockIndex from '../../features/StockIndex';

const StocksPage = () => {
  return (
    <>
      <StockIndex />
      <Grid container justifyContent="center" columns={{ xs: 12 }}>
        <Divider flexItem={true} sx={{ margin: '25px', width: '20%', height: '5px', backgroundColor: '#ffffff' }} />
      </Grid>
      <BigMacIndex />
    </>
  );
};

export default StocksPage;
