import React, { useState, useEffect } from 'react';
import './style.css';
import { brentCrudeOilPrices } from '../../../data/brentCrudeOilPrices'; // Commodities API
import { coffeePrice } from '../../../data/arabicaCoffee'; // Commodities API
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
import { Line } from 'react-chartjs-2';

const Commodities = () => {
  const [commodities, setCommodities] = useState();

  useEffect(() => {
    setCommodities(data);
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            let yAxis = context.dataset.yAxisID;
            if (label) {
              label += ': ';
            }
            if (yAxis) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
              return label;
            }
          }
        }
      }
    },
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 1,
        to: 0.5,
        loop: false
      }
    },
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        min: 0,
        max: 150
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        min: 0,
        max: 5
      }
    },
    pointHoverBorderWidth: 5
  };

  const data = {
    labels: brentCrudeOilPrices.map((data) => data.date).reverse(),
    datasets: [
      {
        label: 'Brent Crude Oil',
        data: brentCrudeOilPrices.map((data) => data.price).reverse(),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        yAxisID: 'y1'
      },
      {
        label: 'Arabica coffee',
        data: coffeePrice.map((data) => data.price / 100).reverse(),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y2'
      }
    ]
  };

  return <>{commodities ? <Line options={options} data={commodities} /> : null}</>;
};

export default Commodities;
