import React from 'react';
import { Line } from 'react-chartjs-2';
import './style.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const InflationRates = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            let yAxis = context.dataset.yAxisID;
            if (label) {
              label += ': ';
            }
            if (yAxis) {
              label += context.parsed.y + '%';
              return label;
            }
          }
        }
      }
    },
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 0,
        to: 0.4,
        loop: false
      }
    },
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        min: 0,
        max: 10
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        min: 0,
        max: 5
      }
    },
    pointHoverBorderWidth: 5
  };

  const evolRates = [
    { year: '2021', ratesPrices: { rate: 4.8, rateBCE: 0 } },
    { year: '2022', ratesPrices: { rate: 8.8, rateBCE: 2 } },
    { year: '2023', ratesPrices: { rate: 6.5, rateBCE: 2.9 } },
    { year: '2024', ratesPrices: { rate: 4.1, rateBCE: 1.9 } }
  ];

  const data = {
    datasets: [
      {
        label: 'World inflation',
        data: evolRates,
        borderColor: 'rgb(255,112,67)',
        backgroundColor: 'rgba(255,112,67, 0.5)',
        parsing: {
          xAxisKey: 'year',
          yAxisKey: 'ratesPrices.rate'
        },
        yAxisID: 'y1'
      },
      {
        label: 'European Central Bank',
        data: evolRates,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        parsing: {
          xAxisKey: 'year',
          yAxisKey: 'ratesPrices.rateBCE'
        },
        yAxisID: 'y2'
      }
    ]
  };

  return (
    <>
      <h2>World inflation - European Central Bank (2021 - 2024)</h2>
      {<Line options={options} data={data} />}
      <p className="source">source: International Monetary Fund</p>
    </>
  );
};

export default InflationRates;
