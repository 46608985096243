import React, { useState, useEffect } from 'react';
import News from '../News';
import Grid from '@mui/material/Grid';
import './style.css';
import InflationRates from '../Graphics/InflationRates';
import CircularProgress from '@mui/material/CircularProgress';
const Homepage = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div>
      {<News numberArticle={3} />}
      {loading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
          <Grid container rowSpacing={1} justifyContent="center" columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
            <Grid item xs={12} md={6}>
              <InflationRates />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Homepage;
