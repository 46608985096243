import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress, Input, Skeleton } from '@mui/material';
import { Button } from '@mui/material';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
const BigMacIndex = () => {
  const [bigMacIndex, setBigMacIndex] = useState('');
  const [country, setCountry] = useState({ value: '' });
  const [fetchIndex, setFetchIndex] = useState(false);

  const inputRef = useRef(null);

  const handleChange = (event) => {
    setCountry((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFetchIndex(true);
    if (inputRef) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (fetchIndex === false) return;

    const fetchData = async () => {
      const data = await fetch(
        `https://data.nasdaq.com/api/v3/datasets/ECONOMIST/BIGMAC_${country.value}?column_index=1&api_key=JV6qzZ865Csb7y5_h-hD`
      );
      if (!data.ok) {
        console.clear();
        /*        const messageBig = `An error has occured: ${data.status}`;
        throw new Error(messageBig); */
      }
      const response = await data.json();

      if (fetchIndex) {
        setBigMacIndex(response.dataset);
        setFetchIndex(false);
      }
    };
    fetchData().catch((error) => {
      console.error(error);
    });
  }, [fetchIndex]);

  return (
    <>
      <h1 className="titleStockIndex" style={{ color: '#ffffff' }}>
        Big Mac Index
      </h1>
      <Grid container justifyContent="center" columns={{ xs: 12 }}>
        <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
          <form onSubmit={handleSubmit}>
            <Input
              inputRef={inputRef}
              onChange={handleChange}
              sx={{ color: '#ffffff' }}
              defaultValue={country.value}
              id="standard-basic"
              placeholder="Enter a country (eg. CHE)"
              variant="standard"
              size="normal"
              name="value"
            />
            <Button type="submit">Validate</Button>
          </form>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={11} md={4}>
          {bigMacIndex && fetchIndex === false ? (
            <Card sx={{ minWidth: `50%` }}>
              <h1 style={{ textAlign: 'center', color: '#000' }}>{bigMacIndex.name}</h1>
              <p style={{ textAlign: 'center' }}>
                {bigMacIndex.data[0][0]} = {bigMacIndex.data[0][1]}
              </p>
            </Card>
          ) : (
            <Grid container justifyContent="center">
              {fetchIndex ? (
                <CircularProgress />
              ) : (
                <Skeleton
                  sx={{ bgcolor: 'grey.900' }}
                  animation={false}
                  variant="rectangular"
                  width={`100%`}
                  height={100}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default BigMacIndex;
