import { Grid } from '@mui/material';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';

const SkeletonNews = (props) => {
  const { numberArticle } = props;
  SkeletonNews.propTypes = {
    numberArticle: PropTypes.number
  };

  return (
    <>
      <Grid container spacing={0}>
        {Array(numberArticle)
          .fill()
          .map((item, index) => (
            <Grid sx={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }} key={index} item xs={12} md={4}>
              <Box
                sx={{
                  width: 350,
                  height: 450,
                  backgroundColor: '#ffffff'
                }}>
                <h1 className="news-title">
                  <Skeleton height={36} width={`80%`} />
                </h1>
                <div>
                  <Skeleton height={233} width={350} />
                </div>
                <p style={{ padding: 25, fontSize: '0.9rem' }}>
                  <Skeleton width={`100%`} />
                </p>
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default SkeletonNews;
