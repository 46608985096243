import React, { useEffect, useRef, useState } from 'react';
import { Grid, Input, Button, Skeleton, Card, CircularProgress } from '@mui/material';
import './style.css';
const StockIndex = () => {
  const [stockIndex, setStockIndex] = useState({});
  const [index, setIndex] = useState(false);
  const [parameter, setParameter] = useState({ company: 'IBM' });

  const inputRef = useRef(null);

  const handleChange = (event) => {
    setParameter((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIndex(true);
    if (inputRef) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (index === false) return;

    const fetchData = async () => {
      const data = await fetch(
        `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${parameter.company}&apikey=SZ4JZ4MF1BPF3TML`
      );
      if (!data.ok) {
        console.clear();
        const message = `An error has occured: ${data.status}`;
        throw new Error(message);
      }
      const response = await data.json();
      const keyVariable = 'Global Quote';
      const values = Object.values(response[keyVariable]);
      const stocks = { symbol: values[0], price: values[4], changeRate: values[9] };

      if (index) {
        setStockIndex(stocks);
        setIndex(false);
      }
    };
    fetchData().catch(console.error);
  }, [index]);

  return (
    <>
      <h1 className="titleStockIndex" style={{ color: '#ffffff' }}>
        Stock Index
      </h1>
      <Grid container justifyContent="center" columns={{ xs: 12, md: 12 }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
          <form onSubmit={handleSubmit}>
            <Input
              inputRef={inputRef}
              onChange={handleChange}
              sx={{ color: '#ffffff', minWidth: '210px' }}
              defaultValue={stockIndex.symbol}
              id="standard-basic"
              placeholder="Enter a company (eg. AAPL)"
              variant="standard"
              size="normal"
              name="company"
            />
            <Button type="submit">Validate</Button>
          </form>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" columns={{ xs: 12, md: 12 }}>
        {stockIndex.symbol && index === false ? (
          <Grid item xs={11} md={4}>
            <Card
              sx={{
                minWidth: `50%`,
                backgroundColor: '#e0e0e0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <h1 style={{ color: '#000a12' }} className="company">
                {stockIndex.symbol}
              </h1>
              <p className="stock">{stockIndex.price} USD</p>
              <p className="stock">{stockIndex.changeRate}</p>
            </Card>
          </Grid>
        ) : (
          <Grid container justifyContent="center" columns={{ xs: 12, md: 12 }}>
            <Grid item xs={11} md={4}>
              {index ? (
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <Card
                  sx={{
                    minWidth: `50%`,
                    backgroundColor: 'grey.900',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <Skeleton width={`100%`} height={100} sx={{ bgcolor: 'grey.900' }} animation={false} />
                </Card>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StockIndex;
