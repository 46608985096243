import { useState } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import ArticleIcon from '@mui/icons-material/Article';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AddchartIcon from '@mui/icons-material/Addchart';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
const Menu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Box sx={{ height: '112px', flexGrow: 1 }}>
      <AppBar sx={{ backgroundColor: '#1a237e' }} position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            sx={{ color: '#fff' }}
            aria-label="logo"
            onClick={() => setIsDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h4"
            noWrap
            sx={{ fontWeight: '700', display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}
            component="div">
            easy Trading
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240
          }
        }}
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}>
        <List>
          {[
            { label: 'News', path: '/actualites' },
            { label: 'Currencies & Commodities', path: '/devises' },
            { label: 'Analysis', path: '/analyses' },
            { label: 'Glossary', path: '/lexique' },
            { label: 'Search Index', path: '/entreprises' }
          ].map((item, index) => (
            <ListItem key={item.label} disablePadding>
              <ListItemButton href={item.path}>
                <ListItemIcon>
                  {(() => {
                    switch (index) {
                      case 0:
                        return <ArticleIcon />;
                      case 1:
                        return <CurrencyExchangeIcon />;
                      case 2:
                        return <AnalyticsIcon />;
                      case 3:
                        return <LibraryBooksIcon />;
                      case 4:
                        return <ShowChartIcon />;
                      default:
                        return <AddchartIcon />;
                    }
                  })()}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Menu;
