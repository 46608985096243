import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
import { Bar } from 'react-chartjs-2';

const FederalRates = () => {
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y + '%';
            return label;
          }
        }
      }
    }
  };

  return (
    <Bar
      options={options}
      data={{
        labels: ['27/07/2022', '21/09/2022', '02/11/2022', '01/12/2022', '15/12/2022'],
        datasets: [
          {
            id: 1,
            label: 'Fed',
            data: [1.58, 2.33, 3.08, 3.83, 4.4],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(244, 67, 54, 1)',
            barThickness: 30
          }
        ]
      }}
    />
  );
};

export default FederalRates;
