const coinData = [
  {
    time_period_start: '2022-06-27T00:00:00.0000000Z',
    time_period_end: '2022-07-07T00:00:00.0000000Z',
    time_open: '2022-06-27T00:00:00.0000000Z',
    time_close: '2022-07-06T23:59:00.0000000Z',
    rate_open: 21026.859099471723,
    rate_high: 21483.035629895592,
    rate_low: 18649.610923041666,
    rate_close: 20543.254699884656
  },
  {
    time_period_start: '2022-07-07T00:00:00.0000000Z',
    time_period_end: '2022-07-17T00:00:00.0000000Z',
    time_open: '2022-07-07T00:00:00.0000000Z',
    time_close: '2022-07-16T23:59:00.0000000Z',
    rate_open: 20543.689874554948,
    rate_high: 22403.94494507261,
    rate_low: 18924.420179186738,
    rate_close: 21210.13919002979
  },
  {
    time_period_start: '2022-07-17T00:00:00.0000000Z',
    time_period_end: '2022-07-27T00:00:00.0000000Z',
    time_open: '2022-07-17T00:00:00.0000000Z',
    time_close: '2022-07-26T23:59:00.0000000Z',
    rate_open: 21190.47310659893,
    rate_high: 24242.343630039646,
    rate_low: 20741.060644311194,
    rate_close: 21285.423953058016
  },
  {
    time_period_start: '2022-07-27T00:00:00.0000000Z',
    time_period_end: '2022-08-06T00:00:00.0000000Z',
    time_open: '2022-07-27T00:00:00.0000000Z',
    time_close: '2022-08-03T23:59:00.0000000Z',
    rate_open: 21257.481117131563,
    rate_high: 24604.72427867942,
    rate_low: 21048.794914711947,
    rate_close: 22850.97226341419
  },
  {
    time_period_start: '2022-08-06T00:00:00.0000000Z',
    time_period_end: '2022-08-16T00:00:00.0000000Z',
    time_open: '2022-08-09T00:00:00.0000000Z',
    time_close: '2022-08-15T23:59:00.0000000Z',
    rate_open: 23818.359269925757,
    rate_high: 25189.568661333138,
    rate_low: 22728.721636003447,
    rate_close: 24095.19634044229
  },
  {
    time_period_start: '2022-08-16T00:00:00.0000000Z',
    time_period_end: '2022-08-26T00:00:00.0000000Z',
    time_open: '2022-08-16T00:00:00.0000000Z',
    time_close: '2022-08-25T23:59:00.0000000Z',
    rate_open: 24099.54899760609,
    rate_high: 24435.637393529047,
    rate_low: 20814.910101209036,
    rate_close: 21569.24185312522
  },
  {
    time_period_start: '2022-08-26T00:00:00.0000000Z',
    time_period_end: '2022-09-05T00:00:00.0000000Z',
    time_open: '2022-08-26T00:00:00.0000000Z',
    time_close: '2022-09-04T23:59:00.0000000Z',
    rate_open: 21559.65406060929,
    rate_high: 21819.606853485064,
    rate_low: 19532.354419444233,
    rate_close: 19995.474907197568
  },
  {
    time_period_start: '2022-09-05T00:00:00.0000000Z',
    time_period_end: '2022-09-15T00:00:00.0000000Z',
    time_open: '2022-09-05T00:00:00.0000000Z',
    time_close: '2022-09-14T23:59:00.0000000Z',
    rate_open: 20003.87919398958,
    rate_high: 22719.209759389207,
    rate_low: 18566.973264746808,
    rate_close: 20224.04192911362
  },
  {
    time_period_start: '2022-09-15T00:00:00.0000000Z',
    time_period_end: '2022-09-25T00:00:00.0000000Z',
    time_open: '2022-09-15T00:00:00.0000000Z',
    time_close: '2022-09-24T23:59:00.0000000Z',
    rate_open: 20228.72886260778,
    rate_high: 20325.89510477824,
    rate_low: 18204.51953020423,
    rate_close: 18926.698085580145
  },
  {
    time_period_start: '2022-09-25T00:00:00.0000000Z',
    time_period_end: '2022-10-05T00:00:00.0000000Z',
    time_open: '2022-09-25T00:00:00.0000000Z',
    time_close: '2022-10-04T23:59:00.0000000Z',
    rate_open: 18921.955533423414,
    rate_high: 20438.276717545723,
    rate_low: 18501.81098774706,
    rate_close: 20340.382466012892
  },
  {
    time_period_start: '2022-10-05T00:00:00.0000000Z',
    time_period_end: '2022-10-15T00:00:00.0000000Z',
    time_open: '2022-10-05T00:00:00.0000000Z',
    time_close: '2022-10-14T23:59:00.0000000Z',
    rate_open: 20344.25927204931,
    rate_high: 20440.713744091987,
    rate_low: 18235.78055494386,
    rate_close: 19181.37446702291
  },
  {
    time_period_start: '2022-10-15T00:00:00.0000000Z',
    time_period_end: '2022-10-25T00:00:00.0000000Z',
    time_open: '2022-10-15T00:00:00.0000000Z',
    time_close: '2022-10-24T23:59:00.0000000Z',
    rate_open: 19176.03493060715,
    rate_high: 19695.604058745645,
    rate_low: 18706.85098269938,
    rate_close: 19332.219797061072
  },
  {
    time_period_start: '2022-10-25T00:00:00.0000000Z',
    time_period_end: '2022-11-04T00:00:00.0000000Z',
    time_open: '2022-10-25T00:00:00.0000000Z',
    time_close: '2022-11-03T23:59:00.0000000Z',
    rate_open: 19330.059493490215,
    rate_high: 21079.711518306594,
    rate_low: 19250.78987791059,
    rate_close: 20206.207562394517
  },
  {
    time_period_start: '2022-11-04T00:00:00.0000000Z',
    time_period_end: '2022-11-14T00:00:00.0000000Z',
    time_open: '2022-11-04T00:00:00.0000000Z',
    time_close: '2022-11-13T23:59:00.0000000Z',
    rate_open: 20206.949358838327,
    rate_high: 21468.972131373397,
    rate_low: 15587.801413681756,
    rate_close: 16304.97851262256
  },
  {
    time_period_start: '2022-11-14T00:00:00.0000000Z',
    time_period_end: '2022-11-24T00:00:00.0000000Z',
    time_open: '2022-11-14T00:00:00.0000000Z',
    time_close: '2022-11-23T23:59:00.0000000Z',
    rate_open: 16305.958203467406,
    rate_high: 17138.50289927652,
    rate_low: 15490.54950115589,
    rate_close: 16602.485938052894
  },
  {
    time_period_start: '2022-11-24T00:00:00.0000000Z',
    time_period_end: '2022-12-04T00:00:00.0000000Z',
    time_open: '2022-11-24T00:00:00.0000000Z',
    time_close: '2022-12-03T23:59:00.0000000Z',
    rate_open: 16584.83428445302,
    rate_high: 17224.683401330443,
    rate_low: 16007.520204042941,
    rate_close: 16887.13495503132
  },
  {
    time_period_start: '2022-12-04T00:00:00.0000000Z',
    time_period_end: '2022-12-14T00:00:00.0000000Z',
    time_open: '2022-12-04T00:00:00.0000000Z',
    time_close: '2022-12-04T19:24:00.0000000Z',
    rate_open: 16886.648926078164,
    rate_high: 17138.931118462122,
    rate_low: 16881.722905913448,
    rate_close: 17060.305930958508
  }
];

export default coinData;
