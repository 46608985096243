import React, { useEffect, useState } from 'react';
import InflationRates from '../Graphics/InflationRates';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';
import FiatComparisons from '../Graphics/Fiat';

const Analysis = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {loading ? (
        <Grid sx={{ height: '50vh' }} container direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <div>
          <h1 style={{ textAlign: 'center', color: '#ffffff' }}>Analysis</h1>
          <Grid container direction="row" justifyContent="space-around" spacing={2}>
            <Grid item xs={12} md={5}>
              <FiatComparisons />
              <InflationRates />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Analysis;
